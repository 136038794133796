import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
  const isVerified = localStorage.getItem('token');
  const location = useLocation();
  const navigate = useNavigate();

  // Redirect if not verified and not on /verify page
  if (!isVerified && location.pathname !== '/verify') {
    return <Navigate to="/verify" state={{ from: location }} replace />;
  }

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/verify');
  };

  return (
    <>
      {children}
      <div>
        <button
          onClick={handleLogout}
          className="m-4 px-4  py-2 bg-white text-[#e51a4b] font-bold rounded shadow border border-[#e51a4b] hover:bg-gray-200"
        >
          Logout
        </button>
      </div>
    </>
  );
};

export default ProtectedRoutes;
